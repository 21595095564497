import { Calendar as FullCalendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import adaptivePlugin from "@fullcalendar/adaptive";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";

frappe.FullCalendar = FullCalendar;
frappe.FullCalendar.Plugins = [dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin];
frappe.FullCalendar.LimitedPlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin];
frappe.FullCalendar.ResourcesPlugins = [resourceTimelinePlugin, interactionPlugin, adaptivePlugin, resourceTimeGridPlugin];